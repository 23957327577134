/* Functionality */
import React, { Component } from 'react';
import { Switch, Route } from 'react-router-dom';
import { instanceOf } from 'prop-types';
import { withCookies, Cookies } from 'react-cookie';
import { history } from './index';

/* Layout Components */
import Topbar from './components/Topbar';
import Sidebar from './components/Sidebar/Sidebar';

/* Feature Components */
import WelcomeScreen from './components/WelcomeScreen';
import Login from './components/Login/Login';
import CreateUser from './components/CreateUser/CreateUser';
import Profile from './components/Profile/Profile';
import SuggestionsBox from './components/SuggestionsBox/SuggestionsBox';
import CreateMessage from './components/CreateMessage/CreateMessage';
import MessageBoard from './components/MessageBoard/MessageBoard';
import Dashboard from './components/Dashboard/Dashboard';
import Comparative from './components/Comparative/Comparative';
import AgentDetails from './components/AgentDetails/AgentDetails';
import ApplicationUpload from './components/ApplicationUpload/ApplicationUpload';
import AgentFactors from './components/AgentFactors/AgentFactors';
import Leaderboard from './components/Leaderboard/Leaderboard';

/* Styles */
import './App.css';
import 'bootstrap/dist/css/bootstrap.css';

//Maximum age of cookies, in seconds 
const maxAge = 4*60*60; //Set to 4 hours (for half a shift)

class App extends Component {
  /* Initialize cookies */
  static propTypes = {
    cookies: instanceOf(Cookies).isRequired
  };
  
  state = {}

  /* Called in from Login component */
  getUser = (jsn) => {
    this.props.cookies.set('userID', jsn.userID, {path: "/", maxAge: maxAge, sameSite: true});
    this.props.cookies.set('agentID', jsn.agentID, {path: "/", maxAge: maxAge, sameSite: true});
    this.props.cookies.set('loggedIn', true, {path: "/", maxAge: maxAge, sameSite: true});
    this.props.cookies.set('name', jsn.name, {path: "/", maxAge: maxAge, sameSite: true});
    this.props.cookies.set('role', jsn.role, {path: "/", maxAge: maxAge, sameSite: true});
    this.setState(jsn);
  }

  logOut = () => {
    this.props.cookies.remove('userID');
    this.props.cookies.remove('agentID');
    this.props.cookies.remove('loggedIn');
    this.props.cookies.remove('name');
    this.props.cookies.remove('role');
    this.props.cookies.remove('comisiones');
    this.props.cookies.remove('dashboard-mgmt');
    this.props.cookies.remove('comparative');
    this.setState({
      userID: "",
      agentID: "",
      loggedIn: false,
      name: "",
      role: ""
    });

    return history.push('/'); //<Redirect to='/'/>
  }
  
  refreshSidebar = React.createRef();
  componentDidUpdate() {//This forces the sidebar to update the user's roles and ID
    if (this.refreshSidebar.current) {
      this.refreshSidebar.current.handleLogin(this.state);
    }
  }

  componentDidMount() {
    this.setState({
      userID:   this.props.cookies.get('userID') || "",
      agentID:  this.props.cookies.get('agentID') || "",
      loggedIn: this.props.cookies.get('loggedIn') || false,
      name:     this.props.cookies.get('name') || "",
      role:     this.props.cookies.get('role') || ""
    });
  }

  render() {
    return (
      <div className="App">
        <header className="App-header">
          <Topbar loggedIn={this.state.loggedIn} logOut={this.logOut}/>
        </header>
        <div className="row">
          {
            !(this.state.loggedIn) &&
            <div className="col-12">
              <Switch>
                <Route
                exact 
                path="/" 
                render={
                  () => <Login getUser={e => this.getUser(e)} />
                  } />
              </Switch>
            </div>
          }
          {
            this.state.loggedIn &&
            <div className="col-3 has-slider">
              <Sidebar userID={this.props.userID} agentID={this.props.agentID} ref={this.refreshSidebar} />
            </div>
          }
          {
            this.state.loggedIn &&
            <div className="col-9">
              <Switch>
                <Route exact path="/" 
                render={
                  () => <WelcomeScreen name={this.state.name}/>
                }/>
                <Route exact path="/dashboard" 
                render={(props) => <Dashboard {...props} />} 
                />
                <Route exact path="/details/:agentID" 
                render={
                  (props) => <AgentDetails {...props} />
                  } />
                <Route exact path="/people/:userID" 
                render={
                  (props) => <Profile {...props} cookieID={this.state.userID} />
                  } />
                <Route exact path="/comparative/:agentID" 
                render={
                  (props) => <Comparative {...props} /> 
                }/>
                <Route exact path="/suggestions-box/:agentID" component={SuggestionsBox} />
                <Route exact path="/create-user"
                render={
                  () => <CreateUser creatorRole={this.state.role}/>
                  } />
                <Route exact path="/message-board" component={MessageBoard} />
                <Route exact path="/leaderboard" render={
                  () => <Leaderboard role={this.state.role}/>
                  } />
                <Route exact path="/create-message/:userID" component={CreateMessage} />
                <Route exact path="/upload-apps" component={ApplicationUpload} />
                <Route exact path="/update-agent-factors" component={AgentFactors} />
              </Switch>
            </div>
          }
        </div>
      </div>
    );
  }
}

export default withCookies(App);
