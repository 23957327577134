import React, { Component } from 'react';
import { instanceOf } from 'prop-types';
import { withCookies, Cookies } from 'react-cookie';
import BankGoals from '../BankGoals/BankGoals';
import 'bootstrap/dist/css/bootstrap.css';
import './AgentDetails.css'
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

const mySwal = withReactContent(Swal)
const maxAge = 6 * 60 * 60; //Máximo una consulta en 6 horas

class AgentDetails extends Component {
  /* Initialize cookies */
  static propTypes = {
    cookies: instanceOf(Cookies).isRequired
  };

  state = this.props.cookies.get('comisiones') || {};

  componentWillReceiveProps({ agentID }) {
    if (this.props.match) {
      return;
    }
    else {
      const dataOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify({ agentID })
      };


      fetch('https://corucall.herokuapp.com/db/agent-details-monthly', dataOptions)
        .then(res => {
          res.json().then(obj => {
            this.setState({ ...obj });
            
          })
            .catch(err => mySwal.fire({
              title: "Error",
              text: err.message,
              type: "error"
            }));
        })
        .catch(err => console.log(err));
      }
    }

componentDidMount() {
  const usedID = (this.props.match ? this.props.match.params.agentID : this.props.agentID);
  //Uso cookies para no volver a sacar esta info si no es necesario
  //Así evito sobrecargar al servidor
  const dataOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json"
    },
    body: JSON.stringify({ agentID: usedID })
  };

  //Llamada para la frase motivacional API
  fetch('https://corucall.herokuapp.com/api/frase-test')
    .then(resFrase => {
      resFrase.json().then(frase => {
        this.setState({ ...frase });
        if (this.state.bankData) { //Si la cookie no cargó porque no existe...
          return;
        }
        else { //...Entonces llama a la API
          //Llama a API para conseguir info del mes
          fetch('https://corucall.herokuapp.com/db/agent-details-monthly', dataOptions)
            .then(res => {
              res.json().then(obj => {
                this.setState({ ...obj });
                //Sólo montar la cookie si se llamó desde el agente mismo
                if (this.props.match) this.props.cookies.set('comisiones', JSON.stringify(obj), { path: "/", maxAge: maxAge, sameSite: true })
              })
                .catch(err => mySwal.fire({
                  title: "Error",
                  text: err.message,
                  type: "error"
                }));
            })
            .catch(err => console.log(err));
        }
      })
        .catch(err => console.log(err));
    })
    .catch(err => console.log(err));

}

//Pasar las vistas de los bancos a componentes
//Y además, utilizar formatos condicionales para metas
render() {
  return (
    <div className="agent-details">
      <div className="agent-name">{this.state.name}</div>
      <div className="comisiones row">
        <div className="col-md-6 border-right">
          Hoy por hoy, ya te ganaste <br />
          <span className="fee"> ${this.state.earnedComm} pesos</span> <br />
          en comisiones. <br />
        </div>
        <div className="col-md-6">
          Si sigues en este ritmo, este mes vas a ganarte <br />
          <span className="fee">${this.state.potComm} pesotes</span> <br />
          de comisiones. <br />
        </div>
        <div className="col-md-12">
          <strong className="eval-text"> ¡Bien ahí, crack! </strong>
        </div>
        <div className="frase--matona">
          <em className="frase--motivacional">{this.state.frase}</em>
        </div>
        <div className="container">
          {/* Funciones enfermas para generar grid de bancos de 3*x */}
          {this.state.bankData &&
            this.state.bankData
              .map((x, i, ar) => ar.slice(i * 3, i * 3 + 3))
              .map((el, i) => {
                return (<div key={`row-${i}`} className="row">
                  {el.map((ie, j) => {
                    return (<BankGoals key={(i * 3) + j} {...ie} />)
                  })}
                </div>);
              })
          }
        </div>
      </div>
      {(this.state.factorAsistencia && this.state.factorCalidad) &&
        <div className="factores-agente">
          Tu cálculo de comisiones considera un factor de RH de <strong>{this.state.factorAsistencia * 100}% </strong>
          y un factor por calidad de <strong>{this.state.factorCalidad * 100}%. </strong> <br />
          ¡No olvides asistir puntualmente y mantener alta la calidad para obtener mejores comisiones!
          </div>
      }
    </div>
  );
}
}

export default withCookies(AgentDetails);