import React, { Component } from 'react';
import BoardAgent from './BoardAgent';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

import './Leaderboard.css';

const mySwal = withReactContent(Swal);

class Leaderboard extends Component {
  state = {};

  componentDidMount() {
    fetch('https://corucall.herokuapp.com/db/query-xps')
      .then(res => {
        res.json()
          .then(data => {
            this.setState({agents : data.agents});
          }).catch(err => {
            mySwal.fire({
              title: "Error",
              text: err.message,
              type: "error"
            });
          });
      }).catch(err => {
        mySwal.fire({
          title: "Error",
          text: err.message,
          type: "error"
        });
      });
  }

  refreshLeaderboard() {
    fetch('https://corucall.herokuapp.com/db/update-xps')
      .then(res => {
        res.json()
          .then(data => {
            this.setState({agents: data.agents});
            mySwal.fire({
              title: "Actualizado",
              text: data.message,
              type: "success"
            })
          })
      })
      .catch(err => {
        mySwal.fire({
          title: "Error",
          text: err.message,
          type: "error"
        });
      });
  }

  render() {
    return (
      <div className="leaderboard">
      <h2>Ranking de agentes</h2>
      <div className="leaderboard-container">
      <div className="header">
        <div className="pos-header">Pos</div>
        <div className="agente-header">Agente</div>
      </div>
        { this.state.agents &&
          this.state.agents.map((el, i) => {
            return (
              <BoardAgent key={`Agente-${i}`} pos={i+1} {...el}/>
              )
            })}
        </div>
        {
          (["Supervisor", "Manager", "Admin"].indexOf(this.props.role) !== -1) &&
          <button
            className="btn btn-outline-info"
            onClick={this.refreshLeaderboard}>
            Refresh
          </button>
        }
      </div>
    )
  }
}

export default Leaderboard;