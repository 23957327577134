import React, { Component } from 'react';

class WelcomeScreen extends Component {

  render () {
    return(
      <div className="welcome-screen">
        <h1>¡Bienvenido, {this.props.name}!</h1>
        <img className="welcome-img" src="assets/report-verde.png" alt="Welcome"/>
      </div>
    );
  }
};

export default WelcomeScreen;