import React, { Component } from 'react';
import "./Login.css";
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

const mySwal = withReactContent(Swal)

class Login extends Component {
  state = {
    username: "",
    password: ""
  }

  handleLogin = (e) => {
    e.preventDefault();
    const data = {...this.state}
    let options = {
      method : "POST",
      headers : {
        "Content-Type" : "application/json",
        "Allow-Control-Allow-Origin" : "*"
      },
      body : JSON.stringify(data)
    };
    fetch('https://corucall.herokuapp.com/auth/login', options)
    .then((res) => {
      this.setState({username: "", password: ""});
      res.json()
      .then(jsn => {
        if (jsn.message) {
          mySwal.fire({
            title: "Revisa la información",
            text: jsn.message,
            type: "warning"
          })
        }

        if (jsn.loggedIn) {
          this.props.getUser(jsn);
        }
      })
        .catch(err => console.log(err));
    }).catch(err => console.log(err));
  }

  handleChange = (e) => {
    const { name, value } = e.target;
    this.setState({
      [name]: value
    });
  }

  render() {
    return (
      <form className="login-form">
        <label htmlFor="username">Usuario:</label>
        <input onChange={e => this.handleChange(e)} value={this.state.username} name="username" className="input-login--rounded" type="text" placeholder="bprum" />
        <label htmlFor="password">Contraseña:</label>
        <input onChange={e => this.handleChange(e)} value={this.state.password} name="password" className="input-login--rounded" type="password" placeholder="******" />
        <button onClick={e => this.handleLogin(e)} type="submit" className="btn btn-outline-success btn-margin">Ingresar</button>
      </form>
    )
  }
}

export default Login;