import React from 'react'
import { NavLink } from 'react-router-dom';

const ListElement = (props) => {
  return (
    <NavLink to={props.route} 
    className="list-group-item list-group-item-action"
    activeClassName="active">
    {`${props.text}`}
    </NavLink>
  );
}

export default ListElement;