import React, { Component } from 'react';
import './ComparativeMonth.css'

class ComparativeMonth extends Component {
  render (props) {
    return (
      <div className="col-md-4">
        <div className={`monthly-result monthly-result-top`}>
          <h3>{this.props.month}/2019</h3>
          <h4>Solicitudes: {this.props.submitted} apps</h4>
          <h4>Aprobaciones: {this.props.approved} tarj.</h4>
        </div>
      </div>
    )
  }
}

export default ComparativeMonth;