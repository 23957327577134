import React, { Component } from 'react';

class BankGoals extends Component {

  render() {
    return (
      <div className="col-md-4">
        <div className={(this.props.bankNo < 3) ? `banco banco-top` : `banco`}>
          <h3>{this.props.bankName}</h3>
          <h4>Solicitudes: {this.props.submitted} apps</h4>
          {
            (this.props.goalType === "aprobadas") &&
            <h4>Aprobaciones: {this.props.approved} tarj.</h4>
          }
          <h4>Meta: {this.props.goal} {this.props.goalType}</h4>
          {
            this.props.runRate &&
            <h4>RR: {this.props.runRate} {this.props.goalType}</h4>
          }
        </div>
      </div>
    );
  }
}

export default BankGoals;