import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import './BoardAgent.css';

class BoardAgent extends Component {
  render(props) {
    return (
      <div className="board-agent">
        <div className="rank">{this.props.pos}</div>
        <div className="agent-name">
          <Link className="people" to={`/people/${this.props.user_id}`}>{this.props.usuario_zendesk}</Link>
        </div>
        <div className="puntos">
          <span className="level">Nivel: {this.props.level}</span>
          <span className="xp">{this.props.experience} XP</span>
        </div>
      </div>
    )
  }
}

export default BoardAgent;