import React, { Component } from 'react';
import './ApplicationUpload.css';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

const mySwal = withReactContent(Swal)

class ApplicationUpload extends Component {

  uploadApps = (e) => {
    let file = document.getElementById('files').files[0];

    if (file.type === "text/csv") {
      let reader = new FileReader();
      reader.onload = ((reader) => {
        return () => {
          const contents = reader.result;
          const lines = contents.split('\n'); //Array de filas

          this.sendAppsToBackend(lines);
        }
      })(reader);

      reader.readAsText(file);
    }
    else {
      mySwal.fire({
        title: "Error",
        text: "Sólo puedes subir archivos en formato CSV.",
        type: "error"
      })
    }
  }

  sendAppsToBackend = (lines) => {
    //Format as JSON
    const keys = lines[0].split(','); //Primera fila es headers
    const values = lines.slice(1, lines.length);

    //Convert each value row to JSON object
    const objects = values.map(el => {
      let curObj = {};
      const iv = el.split(',');
      keys.forEach((ie, j) => {
        if (iv[j] !== "") {
          curObj[ie] = iv[j]; //Asignar llave a objeto, si no está vacío
        }
      });
      return curObj;
    });
    
    const data = objects.filter(el => typeof el.idTicket !== "undefined");
    
    const options = {
      method : "POST",
      headers : {
        "Content-Type" : "application/json"
      },
      body : JSON.stringify({tickets: data})
    };

    fetch('https://corucall.herokuapp.com/db/actualizar', options)
    .then((res) => {
      res.json()
      .then(jsn => mySwal.fire({
        title: "¡Enviado!",
        text: jsn.message,
        type: "success"
      }))
      .catch(err => console.log(err));
    }).catch(err => console.log(err));
  }

  render() {
    return (
      <div className="application-upload">
        <h2>Subir solicitudes de tarjeta</h2>
        <label htmlFor=""></label>
        <div className="file-upload">
          <input
            className="input--rounded"
            type="file"
            id="files"
            name="apps" />
          <output id="list"></output>
        </div>
        <button
          className="btn btn-outline-info seleccionar"
          onClick={file => this.uploadApps(file)}> Subir </button>
      </div>
    );
  }
}

export default ApplicationUpload;