import React, { Component } from 'react';
import 'bootstrap/dist/css/bootstrap.css';
import ListElement from './ListElement';

class Sidebar extends Component {
  state = {
    agentID : this.props.agentID,
    userID : this.props.userID,
    role: this.props.role
  }

  handleLogin = (props) => {
    this.setState(props);
  }

  render() {
    return (
      //This uses conditional rendering to display routes according to roles
      
      <div className="list-group">
        {(["Supervisor", "Manager"].indexOf(this.state.role) !== -1) && <ListElement key={1} text="Dashboard" route="/dashboard" />}
        {(this.state.role === 'Agent') && <ListElement key={2} text="Mi desempeño" route={`/details/${this.state.agentID}`} />}
        {(this.state.role === 'Agent') && <ListElement key={3} text="Mes a Mes" route={`/comparative/${this.state.agentID}`} />}
        <ListElement key={4} text="Mi perfil" route={`/people/${this.state.userID}`} />
        <ListElement key={5} text="Ranking" route={`/leaderboard`} />
        <ListElement key={6} text="Anuncios" route={`/message-board`} />
        {(["Supervisor", "Manager", "Admin"].indexOf(this.state.role) !== -1) && <ListElement key={7} text="Crear anuncios" route={`/create-message/${this.state.userID}`} />}
        <ListElement key={8} text="Buzón de Sugerencias" route={`/suggestions-box/${this.state.userID}`} />
        {(["Supervisor", "Manager", "Admin"].indexOf(this.state.role) !== -1) && <ListElement key={9} text="Alta de usuario" route={`/create-user`} />}
        {(this.state.role === 'Admin') && <ListElement key={10} text="Baja de usuario" route={`/delete-user`} />}
        {(["Supervisor", "Manager", "Admin"].indexOf(this.state.role) !== -1) && <ListElement key={11} text="Subir apps" route={`/upload-apps`} />}
        {(["Manager", "Admin"].indexOf(this.state.role) !== -1) && <ListElement key={12} text="Factores de comisiones" route={`/update-agent-factors`} />}
      </div>
    )
  }
}

export default Sidebar;