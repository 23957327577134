import React, { Component } from 'react';
import { Circle } from 'rc-progress';
import './Profile.css';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

const mySwal = withReactContent(Swal)

class Profile extends Component {
  state = {
    description: ``,
    originalDescription: ``,
    newHobby: "",
    hobbies: [],
    originalHobbies: [],
    userInfo: {},
    displayHobbyButton: false,
    displayDescriptionButton: false,
    shouldUpdate: false,
    canModify: false
  }

  componentWillReceiveProps(e) {
    fetch(`https://corucall.herokuapp.com/api/people/${e.match.params.userID}`)
      .then(res => {
        res.json().then(jsn => {
          this.setState({
            description: jsn.description,
            originalDescription: jsn.description,
            hobbies: jsn.hobbies,
            originalHobbies: jsn.hobbies,
            badges: jsn.badges,
            userInfo: jsn.user,
            clean_date: new Date(jsn.user.join_date).toLocaleDateString("es-MX", { day: "numeric", year: "numeric", month: "long" })
          })

          if (this.state.userInfo.role === "Agent") {
            const options = {
              method: "POST",
              headers: {
                "Content-Type": "application/json"
              },
              body: JSON.stringify({ userID: e.match.params.userID })
            }
            fetch(`https://corucall.herokuapp.com/db/agent-xp/`, options)
              .then(resp => {
                resp.json()
                  .then(data => {
                    this.setState({ ...data }); //Importa métricas de XP y nivel
                  })
                  .catch(err => { })
              })
              .catch(err => { })
          }
        })
          .catch(err => console.log(err));
      })
      .catch(err => console.log(err));
  }

  componentWillUnmount() {
    if (this.state.shouldUpdate) {
      let data = {
        description: this.state.description,
        hobbies: this.state.hobbies
      };
      let options = {
        method: "PUT",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify(data)
      };
      fetch(`https://corucall.herokuapp.com/api/people/${this.props.match.params.userID}`, options)
        .then(res => {
          console.log(res);
        })
        .catch(err => console.log(err));
    }
  }

  componentDidMount() {
    fetch(`https://corucall.herokuapp.com/api/people/${this.props.match.params.userID}`)
      .then(res => {
        res.json().then(jsn => {
          this.setState({
            description: jsn.description,
            originalDescription: jsn.description,
            hobbies: jsn.hobbies,
            originalHobbies: jsn.hobbies,
            badges: jsn.badges,
            userInfo: jsn.user,
            clean_date: new Date(jsn.user.join_date).toLocaleDateString("es-MX", { day: "numeric", year: "numeric", month: "long" })
          })

          if (this.state.userInfo.role === "Agent") {
            const options = {
              method: "POST",
              headers: {
                "Content-Type": "application/json"
              },
              body: JSON.stringify({ userID: this.props.match.params.userID })
            }

            fetch(`https://corucall.herokuapp.com/db/agent-xp/`, options)
            .then(resp => {
                resp.json()
                  .then(data => {
                    this.setState({ ...data }); //Importa métricas de XP y nivel
                  })
                  .catch(err => { })
              })
              .catch(err => { })
          }

          var tx = document.getElementsByTagName('textarea');
          for (var i = 0; i < tx.length; i++) {
            tx[i].setAttribute('style', 'height:' + (tx[i].scrollHeight) + 'px;overflow-y:hidden;');
            tx[i].addEventListener("input", OnInput, false);
          }
        })
          .catch(err => console.log(err));
      })
      .catch(err => console.log(err));

    function OnInput() {
      this.style.height = 'auto';
      this.style.height = (this.scrollHeight) + 'px';
    }
  }

  handleInformationSubmit = (e) => {
    mySwal.fire({
      title: 'Perfil',
      text: '¿Quieres actualizar tu información?',
      type: 'info',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Sí',
      cancelButtonText: 'Mejor no',
    })
      .then(result => {
        if (result.value) {
          Swal.fire({
            title: '¡Listo!',
            text: 'Ya actualizamos tu info.',
            type: 'success'
          });
          this.setState({ originalDescription: this.state.description });
          this.setState({ originalHobbies: this.state.hobbies });
          this.setState({ displayHobbyButton: false });
          this.setState({ displayDescriptionButton: false });
          this.setState({ shouldUpdate: true });
        }
        else {
          this.setState({ description: this.state.originalDescription });
          this.setState({ hobbies: this.state.originalHobbies });
          this.setState({ displayHobbyButton: false });
          this.setState({ displayDescriptionButton: false });
        }
      })
  }

  deleteHobby = (i) => {
    const updatedHobbies = this.state.hobbies;
    updatedHobbies.splice(i, 1);
    this.setState({ hobbies: updatedHobbies });
  }

  handleChange = (e) => {
    const { name, value } = e.target;
    this.setState({
      [name]: value
    });
    if (name === "description") {
      this.setState({ displayDescriptionButton: true })
    }
  };

  render() {
    return (
      <div className="profile">
        <div className="profile-head">
          <img className="profile-picture"
            src={
              (
                typeof this.state.userInfo.image_url !== 'undefined' &&
                this.state.userInfo.image_url.length > 0
              ) ?
                this.state.userInfo.image_url :
                "/assets/placeholder-profile.jpg"
            }
            alt="Diego Rodríguez" />
          <div className="profile-head-text">
            <h2>{this.state.userInfo.name}</h2>
            <h3>{this.state.userInfo.job_title}</h3>
            <span className="join-date">En Coru desde {
              (typeof this.state.userInfo.join_date !== 'undefined') ?
                `el ${this.state.clean_date}` :
                'hace ya tiempo'} {/* febrero de 2019 */}</span>
          </div>
        </div>
        <div className="profile-body">
          <textarea className="profile-description"
            name="description"
            id="profile-description"
            readOnly={!(this.props.cookieID === this.props.match.params.userID)}
            placeholder="Escribe un poco acerca de ti..."
            value={this.state.description}
            onChange={e => this.handleChange(e)}
          />
          {this.state.displayDescriptionButton &&
            <button
              className="hobby-button btn btn-success"
              onClick={this.handleInformationSubmit}>
              Actualizar
            </button>
          }
          {this.state.xp &&
            <div className="xp-container">
              <h3>Experiencia:</h3>
              <h4>Al día de hoy, eres agente nivel <strong>{this.state.level}</strong> </h4>
              <Circle
                className="xp-circle"
                percent={Math.round((this.state.xp / this.state.interval) * 100)}
                strokeWidth="4"
                strokeColor="#041849"
              />
              <p>Tienes {this.state.xp} puntos y te faltan {this.state.interval - this.state.xp} para llegar al siguiente nivel.</p>
              <p>Obtén 10 puntos por aplicación enviada y <strong>50</strong> por aprobada</p>
            </div>
          }
          <h3>Pasatiempos:</h3>
          <ul className="hobbies">
            {
              this.state.hobbies.map((el, i) => {
                if (!this.state.displayHobbyButton) {
                  return (
                    <li key={`hobby-${i}`} className="hobby-item col-md-4">{el}</li>
                  );
                }
                else {
                  return (
                    <li key={`hobby-${i}`} className="hobby-item col-md-4">
                      {el}
                      <button className="erase-hobby" type='button' onClick={e => this.deleteHobby(i)}>&#10006;</button>
                    </li>
                  );
                }
              })
            }
            <li className="hobby-item">
              {/* Field to add new hobby */}
              {(this.props.cookieID === this.props.match.params.userID) &&
                <textarea className="hobby-item hobby-input"
                  name="newHobby"
                  id="add-hobby"
                  cols="10"
                  rows="1"
                  value={this.state.newHobby}
                  onChange={e => this.handleChange(e)}
                  onKeyDown={(e) => {
                    if (e.keyCode === 13) {
                      e.preventDefault();
                      if (this.state.newHobby.length > 0) {
                        this.state.hobbies.push(this.state.newHobby);
                        this.setState({ newHobby: '' });
                        this.setState({ displayHobbyButton: true });
                      }
                    }
                  }}
                />
              }
            </li>
          </ul>
          {this.state.displayHobbyButton &&
            <button className="hobby-button btn btn-success" onClick={this.handleInformationSubmit}>Actualizar</button>
          }
        </div>
      </div>
    );
  }
}

export default Profile;