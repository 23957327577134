import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import './Topbar.css'
import 'bootstrap/dist/css/bootstrap.css';

class Topbar extends Component {
  render() {
    return (
      <nav className="navbar-brand">
        <Link to="/" className={(this.props.loggedIn) ? "topbar-link main-menu" : 'topbar-link'}>
          Coru - Call Center
        </Link>
        {
        this.props.loggedIn &&
        <div onClick={this.props.logOut} className="topbar-link authentication">
          Salir
        </div>
        }
      </nav>
    );
  }
}

export default Topbar;