import React, { Component } from 'react';
import BoardPost from './BoardPost';
import './MessageBoard.css'

class MessageBoard extends Component {

  state = {
    messageList : []
  };

  //TODO: gestionar las respuestas de manera inteligente (¿correo? ¿agregador? ¿buzón?)
  replyHandler = () => {
    console.log("¡Alguien quiso responder a tu post!")
  }

  componentWillMount() {
    fetch('https://corucall.herokuapp.com/api/message-board')
    .then(res => {
      res.json().then(list => {
        this.setState({messageList : list.messageList});
      }).catch(err => {
        console.log(err);
      });
    })
    .catch(err => console.log(err));
  }

  render () {
    return (
      <div className="message-board">
        {this.state.messageList.map((el, i) => {
          return(<BoardPost key={i} {...el} handleReply={this.replyHandler} />)
        })}
      </div>
    );
  }
};

export default MessageBoard;