import React, { Component } from 'react';
import { instanceOf } from 'prop-types';
import { withCookies, Cookies } from 'react-cookie';
import BankGoals from '../BankGoals/BankGoals';
import AgentDetails from '../AgentDetails/AgentDetails';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

import './Dashboard.css';

const mySwal = withReactContent(Swal)
const maxAge = 2 * 60 * 60; //Máximo una consulta en 2 horas

class Dashboard extends Component {
  /* Initialize cookies */
  static propTypes = {
    cookies: instanceOf(Cookies).isRequired
  };

  state = this.props.cookies.get('dashboard-mgmt') || {};

  handleChange = (e) => {
    const { value } = e.target;
    this.setState({detailAgentID : value})
  }

  componentDidMount() {
    //Uso cookies para no volver a sacar esta info si no es necesario
    //Así evito sobrecargar al servidor
    //Carga lista de agentes
    fetch('https://corucall.herokuapp.com/api/agents-list')
      .then(resp => {
        resp.json()
          .then(agents => {
            this.setState({ ...agents })
            if (this.state.bankData) { //Si la cookie no cargó porque no existe...
              return;
            }
            else { //...Entonces llama a la API
              //Llama a API para conseguir info del mes
              fetch('https://corucall.herokuapp.com/db/dashboard-mgmt')
                .then(res => {
                  res.json().then(obj => {
                    this.setState({ ...obj });
                    this.props.cookies.set('dashboard-mgmt', JSON.stringify(obj), { path: "/", maxAge: maxAge, sameSite: true })
                  })
                    .catch(err => mySwal.fire({
                      title: "Error",
                      text: err.message,
                      type: "error"
                    }));
                })
                .catch(err => mySwal.fire({
                  title: "Error",
                  text: err.message,
                  type: "error"
                }));
            }
          })
          .catch(err => mySwal.fire({
            title: "Error",
            text: err.message,
            type: "error"
          }));
      })
      .catch(err => mySwal.fire({
        title: "Error",
        text: err.message,
        type: "error"
      }));
  }

  render() {
    return (
      <div className="dashboard">
        <h2>{this.state.title}</h2>
        {/* Funciones para display de los bancos */}
        {this.state.bankData &&
          this.state.bankData
            .map((x, i, ar) => ar.slice(i * 3, i * 3 + 3))
            .map((el, i) => {
              return (<div key={`row-${i}`} className="row">
                {el.map((ie, j) => {
                  return (<BankGoals key={(i * 3) + j} {...ie} />)
                })}
              </div>);
            })
        }
        <hr className="division" />
        {this.state.agents &&
          <div className="consulta">
            <h2>Consulta de agentes</h2>
            <select
              value={this.state.selectAgent}
              className="input--rounded--complement"
              onChange={e => this.handleChange(e)}
              id="select-agente">
              <option value="select" className="select">Seleccionar...</option>
              {this.state.agents.map((el, i) => {
                return (
                  <option
                    key={`Agente-${i}`}
                    value={el._id}>
                    {el.usuario_tmg}
                  </option>
                );
              })}
            </select>
            {(this.state.detailAgentID && this.state.detailAgentID !== "select") &&
              <AgentDetails agentID={this.state.detailAgentID}/>
            }
          </div>
        }

      </div>
    );
  }
}

export default withCookies(Dashboard);