import React, { Component } from 'react';
import './SuggestionsBox.css'
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

const mySwal = withReactContent(Swal)

const anonymousObjectID = "5cb0045c1c9d4400001e9614";

class SuggestionsBox extends Component {

  state = {
    title : "",
    directed_to: "",
    body: "",
    is_anonymous: false,
    signed_by: this.props.match.params.agentID
  }

  handleChange = (e) => {
    const { name, value } = e.target;
    this.setState({
      [name]:value
    });
  }

  handleCheck = (e) => {
    //Protect the poster's identity if requested
    this.setState({
      is_anonymous : !this.state.is_anonymous,
      signed_by: ((this.state.is_anonymous === false) ? anonymousObjectID : this.props.match.params.agentID)
    });
  }

  sendData = e => {
    e.preventDefault();
    const data = {...this.state}
    let options = {
      method : "POST",
      headers : {
        "Content-Type" : "application/json"
      },
      body : JSON.stringify(data)
    };
    //Send to API
    fetch('https://corucall.herokuapp.com/api/feedback', options)
    .then((res) => {
      this.setState({title: "", directed_to: "", body: ""}); //Erase data so the form stays blank
      res.json()
      .then(jsn => mySwal.fire({
        title: "Gracias por tus comentarios",
        text: jsn.message,
        type: "success"
      }))
      .catch(err => console.log(err));
    }).catch(err => console.log(err));
  }


  render() {
    return (
      <div className="suggestions-box">
        <form className="suggestions-form" action="">
          <label htmlFor="title">Título:</label>
          <input onChange={e => this.handleChange(e)} className="input--rounded" value={this.state.title} name="title" placeholder="¿De qué se trata tu mensaje?" type="text" />
          <label htmlFor="directed_to">Receptor:</label>
          <input onChange={e => this.handleChange(e)} className="input--rounded" value={this.state.directed_to} name="directed_to" placeholder="¿A quién va dirigido?" type="text" />
          <label htmlFor="body">Texto:</label>
          <textarea onChange={e => this.handleChange(e)} rows="4" cols="25" className="input--rounded" value={this.state.body} name="body" placeholder="¿De qué quieres escribir? ¿Qué puede mejorar? ¿O qué quieres agradecer?" type="text" />
          <div className="checkbox-container">
            <label htmlFor="is_anonymous">¿Deseas que tu respuesta sea anónima?*</label>
            <input onChange={e => this.handleCheck(e)} name="is_anonymous" type="checkbox" className="checkbox" />
          </div>
          <button onClick={e => this.sendData(e)} type="button" className="btn btn-outline-success">Enviar</button>
        </form>
        <small>*Si esperas una respuesta, por favor no dejes un mensaje anónimo</small>
      </div>
    )
  }
}

export default SuggestionsBox;