import React, { Component } from 'react';
import DatePicker, { registerLocale } from 'react-datepicker';
import esMX from 'date-fns/locale/es';
import "react-datepicker/dist/react-datepicker.css";
import 'bootstrap/dist/css/bootstrap.css';
import './CreateUser.css';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

const mySwal = withReactContent(Swal);

registerLocale('es-MX', esMX);

class CreateUser extends Component {

  state = {
    username: "",
    password: "",
    name: "",
    join_date: new Date(Date.now()),
    date_of_birth: new Date(),
    job_title: "",
    image_url: "",
    role: "",
    group: "CC-A",
    nombre_rh: "",
    usuario_tmg: "",
    usuario_zendesk: ""
  }

  sendData = e => {
    e.preventDefault();
    let data = { ...this.state };
    let options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json" //Aguas con esto, es super importante
      },
      body: JSON.stringify(data)
    };
    //Enviar a la API
    fetch('https://corucall.herokuapp.com/auth/create-user', options)
      .then((res) => {
        //Si es agente del call center, también hay que darlo de alta como agente
        if (this.state.role === "Agent") {
          res.json()
            .then(jsn => {
              data = { ...this.state, user_id: jsn.user_id }
              options = {
                method: "POST",
                headers: {
                  "Content-Type": "application/json" //Aguas con esto, es super importante
                },
                body: JSON.stringify(data)
              };
              fetch('https://corucall.herokuapp.com/api/create-agent', options)
                .then(res => {
                  res.json()
                  .then(jsn =>
                    mySwal.fire({
                    title: "Dado de alta",
                    text: jsn.message,
                    type: "success"
                  }));
                  this.setState({
                    username: "",
                    password: "",
                    name: "",
                    join_date: new Date(Date.now()),
                    date_of_birth: new Date(),
                    job_title: "",
                    image_url: "",
                    role: "",
                    group: "",
                    nombre_rh: "",
                    usuario_tmg: "",
                    usuario_zendesk: ""
                  }); //Borra datos
                })
                .catch(err => console.log(err));
            })
            .catch(err => console.log(err));

        }
        else {
          res.json()
            .then(jsn => {
              mySwal.fire({
                title: "Resultado",
                text: jsn.message,
                type: "info"
              });
              this.setState({
                username: "",
                password: "",
                name: "",
                join_date: new Date(Date.now()),
                date_of_birth: new Date(),
                job_title: "",
                image_url: "",
                role: "",
                group: "",
                nombre_rh: "",
                usuario_tmg: "",
                usuario_zendesk: ""
              }); //Borra datos
            })
            .catch(err => console.log(err));
        }
      })
      .catch(err => console.log(err));

  }

  handleChange = (e) => {
    const { name, value } = e.target;
    this.setState({
      [name]: value
    });
  }

  handleDOBChange = (e) => {
    this.setState({ date_of_birth: new Date(e) });
  }

  handleJoinDateChange = (e) => {
    this.setState({ join_date: new Date(e) });
  }

  render() {
    return (
      <div className="user-creation">
        <form className="user-form" action="">
          <label htmlFor="username">Usuario:</label>
          <input onChange={e => this.handleChange(e)} className="input--rounded" value={this.state.username} name="username" placeholder="Usuario" type="text" />
          <label htmlFor="password">Contraseña:</label>
          <input onChange={e => this.handleChange(e)} className="input--rounded" value={this.state.password} name="password" placeholder="Utiliza una contraseña fuerte" type="password" />
          <label htmlFor="name">Nombre completo:</label>
          <input onChange={e => this.handleChange(e)} className="input--rounded" value={this.state.name} name="name" placeholder="Nombre y apellidos" type="text" />
          {/* TODO: Cambiar esto para que sea file upload */}
          <label htmlFor="image_url">Liga a foto de perfil:</label>
          <input onChange={e => this.handleChange(e)} className="input--rounded" value={this.state.image_url} name="image_url" placeholder="URL" type="text" />
          <label htmlFor="job_title">Puesto:</label>
          <input onChange={e => this.handleChange(e)} className="input--rounded" value={this.state.job_title} name="job_title" placeholder="¿En qué va a trabajar?" type="text" />
          <label htmlFor="job_title">Fecha de nacimiento:</label>
          <DatePicker
            className="input--rounded--complement"
            dateFormat="dd/MM/yyyy"
            selected={this.state.date_of_birth}
            onChange={e => this.handleDOBChange(e)}
            locale="es-MX"
            showMonthDropdown
            showYearDropdown
            placeholderText="Fecha de nacimiento" />
          <label htmlFor="job_title">Fecha de ingreso a Coru:</label>
          <DatePicker
            className="input--rounded--complement"
            dateFormat="dd/MM/yyyy"
            selected={this.state.join_date}
            onChange={e => this.handleJoinDateChange(e)}
            locale="es-MX"
            showMonthDropdown
            showYearDropdown
            placeholderText="Trabaja en Coru desde..." />
          <label htmlFor="role">Rol en la plataforma:</label>
          <select required name="role" value={this.state.role} className="input--rounded" onChange={e => this.handleChange(e)} id="sel2">
            <option>Select...</option>
            <option>Agent</option>
            {(["Manager", "Admin"].indexOf(this.props.creatorRole) !== -1) && <option>Supervisor</option>}
            {(this.props.creatorRole === "Admin") && <option>Manager</option>}
            {(this.props.creatorRole === "Admin") && <option>Admin</option>}
          </select>
          {
            this.state.role === "Agent" && //Conditional rendering 
            <div className="agent-form-complement">
              <label htmlFor="nombre_rh">Nombre RRHH:</label>
              <input onChange={e => this.handleChange(e)} className="input--rounded--complement" value={this.state.nombre_rh} name="nombre_rh" placeholder="Nombre dado de alta en recursos humanos" type="text" />
              <label htmlFor="usuario_tmg">Usuario TMG:</label>
              <input onChange={e => this.handleChange(e)} className="input--rounded--complement" value={this.state.usuario_tmg} name="usuario_tmg" placeholder="Usuario de TMG" type="text" />
              <label htmlFor="usuario_zendesk">Usuario Zendesk:</label>
              <input onChange={e => this.handleChange(e)} className="input--rounded--complement" value={this.state.usuario_zendesk} name="usuario_zendesk" placeholder="Usuario de Zendesk" type="text" />
              <label htmlFor="group">Grupo del agente:</label>
              <select required name="group" 
              value={this.state.group} 
              className="input--rounded--complement" 
              onChange={e => this.handleChange(e)} 
              id="sel1">
                <option>CC-A</option>
                <option>CC-B</option>
                <option>Quality</option>
                <option>Car Insurance</option>
                <option>Data Input</option>
                <option>Cobranza</option>
                <option>Booking</option>
              </select>
            </div>
          }
        </form>
        <button type="button" className="btn btn-outline-success btn-margin" onClick={this.sendData}>Crear usuario</button>
      </div>
    );
  }
}

export default CreateUser;