import React, { Component } from 'react';
import { instanceOf } from 'prop-types';
import { withCookies, Cookies } from 'react-cookie';
import ComparativeMonth from './ComparativeMonth';

import './Comparative.css';

const maxAge = 6 * 60 * 60; //Máximo una consulta en 6 horas

class Comparative extends Component {
  /* Initialize cookies */
  static propTypes = {
    cookies: instanceOf(Cookies).isRequired
  };
  state = this.props.cookies.get('comparative') || {};

  componentDidMount() {

    if (this.state.results) {
      return;
    }
    else {
      const options = {
        method: "POST",
        headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify({ agentID: this.props.match.params.agentID })
    };

    fetch('https://corucall.herokuapp.com/db/comparative', options)
    .then(res => {
        res.json()
          .then(data => {
            this.setState({ results: data.results });
            this.props.cookies.set('comparative', JSON.stringify({results: data.results}), { path: "/", maxAge: maxAge, sameSite: true })
          })
          .catch(err => console.log(err));
        })
      .catch(err => console.log(err));
    }
  }

  render() {
    return (
      <div className="comparative">
        <h2>Tu desempeño mes a mes</h2>
        <div className="row month-result-container">
          {this.state.results &&
            this.state.results.map((el, i) => {
              return (
                <ComparativeMonth key={`month-${i}`} {...el} />
              );
            })
          }
        </div>
      </div>
    );
  }
}

export default withCookies(Comparative);