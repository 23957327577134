import React from 'react';
import { Link } from 'react-router-dom';
import './BoardPost.css';
import 'bootstrap/dist/css/bootstrap.css';

const BoardPost = ({posted_by, title, message, publish_at, enable_reply, handleReply}) => {
  return (
    <div className="board-post">
      <h2>{title}</h2>
      <p className="post-text">{message}</p> 
      <span className="timestamp">Publicado el {new Date(publish_at).toLocaleDateString("es-MX", {weekday: "long", day: "numeric", year: "numeric", month: "long"})} por <Link to={`/people/${posted_by._id}`}><strong>{posted_by.name}</strong></Link></span>
      <br/>
      {enable_reply &&
      <button type="button" className="btn btn-outline-success" onClick={e => handleReply(e)}>Responder</button>}
    </div>
  );
}

export default BoardPost;