import React, { Component } from 'react';
import DatePicker, { registerLocale } from 'react-datepicker';
import esMX from 'date-fns/locale/es';
import "react-datepicker/dist/react-datepicker.css";
import './CreateMessage.css';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

const mySwal = withReactContent(Swal)

registerLocale('es-MX', esMX);

class CreateMessage extends Component {

  state = {
    title: "",
    message: "",
    publish_at: new Date(Date.now()),
    publish_until: new Date(Date.now()),
    enable_reply: false
  }

  sendData = (e) => {
    e.preventDefault();
    const data = {...this.state, posted_by: this.props.match.params.userID}
    let options = {
      method : "POST",
      headers : {
        "Content-Type" : "application/json"
      },
      body : JSON.stringify(data)
    };
    //Enviar a la API
    fetch('https://corucall.herokuapp.com/api/create-message', options)
    .then((res) => {
      this.setState({title: "", message: "", publish_at: new Date(Date.now()), publish_until: new Date()}); //Borra datos para que la forma se quede en blanco
      res.json()
      .then(jsn => mySwal.fire({
        title: "Mensaje generado",
        text: jsn.message,
        type: "success"
      }))
      .catch(err => console.log(err));
    }).catch(err => console.log(err));
  }

  handleChange = (e) => {
    const { name, value } = e.target;
    this.setState({
      [name]: value
    });
  }

  handlePublishChange = (e) => {
    this.setState({ publish_at: new Date(e) });
  }

  handleDepublishChange = (e) => {
    this.setState({ publish_until: new Date(e) });
  }

  handleCheck = (e) => {
    this.setState({
      enable_reply: !this.enable_reply
    });
  }

  render() {
    return (
      <div className="create-message">
        <form className="message-form">
          <label htmlFor="title">Título:</label>
          <input onChange={e => this.handleChange(e)} placeholder="Título" type="text" className="input--rounded" name="title" />
          <label htmlFor="message">Texto:</label>
          <textarea onChange={e => this.handleChange(e)}
            rows="4"
            cols="25"
            className="input--rounded"
            value={this.state.message}
            name="message"
            placeholder="¿Qué quieres comunicar?"
            type="text" />
          <label htmlFor="publish_at">Fecha de inicio de publicación:</label>
          <DatePicker
            className="input--rounded--complement"
            dateFormat="dd/MM/yyyy"
            selected={this.state.publish_at}
            onChange={e => this.handlePublishChange(e)}
            locale="es-MX"
            showMonthDropdown
            showYearDropdown
            placeholderText="Publicar el..." />
          <label htmlFor="publish_until">Fecha de fin de publicación:</label>
          <DatePicker
            className="input--rounded--complement"
            dateFormat="dd/MM/yyyy"
            selected={this.state.publish_until}
            onChange={e => this.handleDepublishChange(e)}
            locale="es-MX"
            showMonthDropdown
            showYearDropdown
            placeholderText="Publicar hasta..." />
          <div className="checkbox-container">
            <label htmlFor="enable_reply">¿Deseas que los usuarios puedan responder a tu mensaje?</label>
            <input onChange={e => this.handleCheck(e)} name="enable_reply" type="checkbox" className="checkbox" />
          </div>
          <button onClick={e => this.sendData(e)} type="button" className="btn btn-outline-success">Enviar</button>
        </form>
      </div>
    );
  }
}

export default CreateMessage;